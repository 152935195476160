@import"colors.scss";
@import"form-styles.scss";

.primary-button {
  color: white;
  background-color: $primary-button-color;
}

.secondary-button {
  color: white;
  background-color: $secondary-button-color;
}

.danger-button {
  color: white;
  background-color: $danger-button-color;
}

.info-button {
  color: white;
  background-color: $info-button-color;
}

.link-text {
  color: $link-text-color !important;
}

a.link-text:focus {
  text-decoration: underline;
}

.display-text {
  color: $display-text-color;
}

.success-text {
  color: $primary-button-color;
}

.danger-text {
  color: $danger-button-color;
}

.alert-text {
  color: $alert-text-color;
}

.info-text {
  color: $info-text-color;
}

.red {
  background-color: $red-background-color;
}

.green {
  background-color: $green-background-color;
  color: #030507 //added to ensure adequate focus.
}

.white {
  background-color: $white-background-color;
}

.yellow {
  background-color: $yellow-background-color;
}