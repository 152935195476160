$primary-button-color:#2E6100;
$secondary-button-color:#0F0FFF;
$danger-button-color:#B30000;
$link-text-color:#0F0FFF;
$display-text-color:#4C5557;
$info-button-color:#118292;
$field-validation-success-color: #42A948;
$field-validation-fail-color: #a94442;
$alert-text-color: #B30000;
$info-text-color:#0C5F6E;

$red-background-color:#DC3545;
$green-background-color:#208836;
$white-background-color:white;
$yellow-background-color: #ffd500;